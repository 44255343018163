import React from "react";
import { StaticQuery, graphql } from "gatsby";

const JobList = ({ data }) => {
  const jobs = data.allDataYaml.nodes.filter(n => n.jobs !== null)[0].jobs;
  if (jobs.length > 0) {
    return (
      <section className="jobs" id="careers">
        <div className="container">
          <h2>Join our team!</h2>
          <ul>
            {jobs.map((j, i) => (
              <li key={i}>
                <a href={j.url} className="primary-link" target="_blank" rel="noopener noreferrer">
                  {j.role}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
  return false;
};

export default props => (
  <StaticQuery
    query={graphql`
      query JobList {
        allDataYaml {
          nodes {
            jobs {
              role
              url
            }
          }
        }
      }
    `}
    render={data => <JobList data={data} {...props} />}
  />
);
