import React from "react";
import { useInView } from "react-intersection-observer";
import unified from "unified";
import parse from "remark-parse";
import remark2react from "remark-react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import TeamList from "../components/TeamList";
import JobList from "../components/JobList";

const weBelieve = [
  ["Impact", "is more important than intent."],
  ["Quality", "should be at the heart of our work products."],
  ["Inclusivity", "is imperative to strengthen our democracy."],
  ["Wholeness", "can lead to kindness, respect, and openness."],
  ["Learning", "is essential to growth and requires intentional work."]
];
const workWith = [
  ["Philanthropists", "Philanthropic efforts can advance your personal or organizational goals and remain a sustainable and measurable part of your business value set. We provide the proper coaching and tools as well as issue-specific landscape analysis for you and/or your brand to identify, take part in, and make an impact on the issues that are most important to you. "],
  ["Brands", "We help brands identify how they want to contribute to making positive change in the world. We set the strategy and find the right platforms, partners, and outlets to reach their goals."],
  ["Talent and Influencers", "We build and foster strategic relationships with passionate, like-minded, high-profile talent and influencers who share not only our clients' enthusiasm for the cause but who have the platform and ability to amplify the message and tell the story to engaged and relevant audiences, ultimately broadening awareness and influencing others to take action."],
  ["Nonprofits", "Our team can support strategy development, establish partnerships, and manage program execution, digital engagement, and creative production to advance your mission and connect with your audience."],
  ["Change Makers", "Our powerhouse design team will bring a visual identity for your campaign/program to life. We have an eye for how to effectively connect cause to creativity for everything from logo creation to full-scale activation/event collateral."]
];

const BelieveItem = ({ word, line }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true
  });

  return (
    <div className={`${inView ? "visible" : "hidden"} believe-item`} ref={ref}>
      <div className="believe-item__word h2">
        <span>{word}</span>
      </div>
      <div className="believe-item__line h4">
        <span>{line}</span>
      </div>
      <div className="divider-pipe-container">
         <div className="divider-pipe">
        </div>
      </div>
    </div>
  );
};

const WorkWithItem = ({ title, description }) => {
  return (
    <div className="column is-4">
      <div className="work-with-item">
        <h3>{title}</h3>
        <div className="work-with-description">
           {description}
        </div>
      </div>
    </div>
  );
};

const Services = ({ services }) => {
  return services.map((s, i) => (
    <div key={i} className="service">
      <h2>{s.category}</h2>
      <div>
        {
          unified()
            .use(parse)
            .use(remark2react)
            .processSync(s.offerings).contents
        }
      </div>
    </div>
  ));
};

export const AboutPageTemplate = ({ title, subtitle, content, contentComponent, services }) => {
  const PageContent = contentComponent || Content;

  return (
    <React.Fragment>
      <section className="hero">
        <div className="container">
          <div className="columns">
            <div className="column is-offset-2">
              <h2>Who we are</h2>
            </div>
            <div className="column is-6 body-1">
              <PageContent content={content} />
            </div>

          </div>
        </div>
      </section>

      <section className="believe">
        <div className="believe-title">
          <h2>We believe</h2>
        </div>
        <div className="container">

          {weBelieve.map((w, i) => (
            <BelieveItem key={i} word={w[0]} line={w[1]} />
          ))}
        </div>
      </section>

      <section className="services" id="services">
        <div className="container">
          <div className="columns">
            <div className="column is-offset-2">
              <h1 className="h1">Our services</h1>
            </div>
            <div className="column">
              <Services services={services} />
            </div>
          </div>
        </div>
      </section>
      <section className="work-with">
        <div className="container">
          <h2>Who we work with</h2>
          <div className="columns is-multiline">
            {workWith.map((w, i) => (
              <WorkWithItem title={w[0]} description={w[1]} />
            ))}
          </div>
        </div>
      </section>
      <section className="team" id="team">
        <div className="container">
          <h2>Meet the team</h2>
          <TeamList />
        </div>
      </section>
      <JobList />
    </React.Fragment>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const title = post.frontmatter.title;
  return (
    <Layout pageId="about" metadata={{ title }}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={title}
        content={post.html}
        services={post.frontmatter.services}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        services {
          category
          offerings
        }
      }
    }
  }
`;
