import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import unified from "unified";
import parse from "remark-parse";
import remark2react from "remark-react";
import Modal from "./Modal";
import LinkedIn from "../img/linkedin.svg";
import Twitter from "../img/twitter.svg";

const TeamModalContent = ({ member }) => {
  return (
    <div className="selected-member">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <img className="member-photo" src={member.photo} alt={member.name} />
            <ul className="social">
              {member.twitter && (
                <li>
                  <a href={member.twitter} rel="noopener noreferrer" target="_blank">
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </li>
              )}
              {member.linkedin && (
                <li>
                  <a href={member.linkedin} rel="noopener noreferrer" target="_blank">
                    <img src={LinkedIn} alt="LinkedIn" />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="column is-7 is-offset-1 selected-member__content">
            <h3>{member.title}</h3>
            <h2 id="modal-title">{member.name}</h2>
            <div className="selected-member__bio" id="modal-description">
              {
                unified()
                  .use(parse)
                  .use(remark2react)
                  .processSync(member.bio).contents
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeamModal = ({ member, selectMember }) => {
  const closeModal = () => selectMember(null);
  return <Modal content={<TeamModalContent member={member} />} closeModal={closeModal} />;
};

const TeamList = ({ data }) => {
  const [selectedMember, selectMember] = useState(null);
  const team = data.allDataYaml.nodes.filter(n => n.team !== null)[0].team;
  return (
    <section className="team-list columns" id="team">
      {selectedMember && <TeamModal member={selectedMember} selectMember={selectMember} />}
      {team.map((t, i) => (
        <div key={i} className="team-member column is-4">
          <button
            className="button--empty"
            onClick={() => selectMember(t)}
            onKeyDown={e => {
              e.keyCode === 13 && selectMember(t);
            }}
          >
            <img src={t.photo} alt={t.name} />
          </button>
          <button
            className="button--empty is-block team-member__name"
            onClick={() => selectMember(t)}
            onKeyDown={e => {
              e.keyCode === 13 && selectMember(t);
            }}
          >
            <h3>{t.name}</h3>
          </button>
          <span>{t.title}</span>
        </div>
      ))}
    </section>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query Team {
        allDataYaml {
          nodes {
            team {
              name
              title
              photo
              bio
              twitter
              linkedin
            }
          }
        }
      }
    `}
    render={data => <TeamList data={data} {...props} />}
  />
);
